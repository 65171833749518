/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"

Rails.start()
Turbolinks.start()
// ActiveStorage.start()

require("jquery")
require("./shared")
require("./editable_textarea")
require("./articles")
require("./article_form")

//= require jquery-ui/widgets/resizable
//= require jquery.remotipart
//= require jquery.slick
//= require select2

import '../css/application.sass'

const images = require.context("../images", true);
const imagePath = name => images(name, true);

document.addEventListener("turbolinks:load", (event) => {
  const press_release_slider = document.querySelector('.press-release-slider');
  if (press_release_slider) {
    press_release_slider.addEventListener('click', (event) => {
      const scroll = document.querySelector('.press-release-scroll');
      const block = scroll.childNodes[0];
      const image = block.childNodes[0];
      scroll.scrollBy({ 
        top: 0,
        left: image.offsetWidth + 10, 
        behavior: 'smooth' 
      });
    });
  }

  const article_filter = document.querySelector('#article_filter');
  if (article_filter) {
    article_filter.addEventListener('change', (event) => {
      if (event.target.checked) {
        document.querySelector('.filter-icon').classList.add('on');
      } else {
        document.querySelector('.filter-icon').classList.remove('on');
      }
    });
  }

  const openmodals = document.querySelectorAll('.modal-open');
  openmodals.forEach(openmodal => {
    openmodal.addEventListener('click', (event) => {
      event.preventDefault();
      let target = $(event.target).data('target');
      if (!target) {
        target = $(event.target).parents('a').data('target');
      }
      toggleModal($(`.${target}`).get(0));
    });
  });

  const overlay = document.querySelector('.modal-overlay');
  if (overlay) {
    overlay.addEventListener('click', (e) => {
      const target = $(e.target).data('target').get(0);
      toggleModal(target);
    });
  }

  const closemodals = document.querySelectorAll('.modal-close');
  closemodals.forEach(closemodal => {
    closemodal.addEventListener('click',
      (e) => toggleModal($(e.target).parents('.modal').get(0))
    )
  });

  const toggleModal = (modal) => {
    modal.classList.toggle('opacity-0');
    modal.classList.toggle('pointer-events-none');
  }

  const slideToNext = (origin) => {
    const container = origin.querySelector('.slide-container');
    const activeSlide = container.querySelector('.slide.translate-x-0');
    const nextSlide = activeSlide.nextElementSibling;
    const counter = origin.querySelector('.current-slide-index');

    if (nextSlide) {
      activeSlide.classList.remove('translate-x-0');
      activeSlide.classList.add('-translate-x-full');
  
      nextSlide.classList.remove('translate-x-full');
      nextSlide.classList.add('translate-x-0');
      counter.innerHTML = "0" + nextSlide.dataset.index;
      setTimeout(() => {
        const firstSlide = container.querySelectorAll('.slide')[0];
        firstSlide.classList.remove('-translate-x-full');
        firstSlide.classList.add('translate-x-full');
        container.append(firstSlide);
      }, 300);
    }
  }

  const slideToPrev = (origin) => {
    const container = origin.querySelector('.slide-container');
    const activeSlide = container.querySelector('.slide.translate-x-0');
    const prevSlide = activeSlide.previousElementSibling;
    const counter = origin.querySelector('.current-slide-index');

    if (prevSlide) {
      activeSlide.classList.remove('translate-x-0');
      activeSlide.classList.add('translate-x-full');
  
      prevSlide.classList.remove('-translate-x-full');
      prevSlide.classList.add('translate-x-0');
      counter.innerHTML = "0" + prevSlide.dataset.index;
      const lastSlide = container.querySelectorAll('.slide')[container.querySelectorAll('.slide').length - 1];
      lastSlide.classList.remove('translate-x-full');
      lastSlide.classList.add('-translate-x-full');
      container.prepend(lastSlide);
    }

  }

  const slideImages = document.querySelectorAll('.slide-image');
  slideImages.forEach(slideImage => {
    slideImage.addEventListener('click',
      (e) => {
        const slide = e.target.closest('.slide');
        if (slide.classList.contains('translate-x-0')) return;

        const origin = e.target.closest('.slide-origin');

        if (slide.classList.contains('translate-x-full')) slideToNext(origin);
        if (slide.classList.contains('-translate-x-full')) slideToPrev(origin);
      }
    )
  });

  const btnNextSlides = document.querySelectorAll('.button-slide-next');
  btnNextSlides.forEach(btnNextSlide => {
    btnNextSlide.addEventListener('click',
      (e) => {
        const origin = e.target.closest('.slide-origin');
        slideToNext(origin);
      }
    )
  });
  
  const btnPrevSlides = document.querySelectorAll('.button-slide-prev');
  btnPrevSlides.forEach(btnPrevSlide => {
    btnPrevSlide.addEventListener('click',
      (e) => {
        const origin = e.target.closest('.slide-origin');
        slideToPrev(origin);
      }
    )
  });

  const resizeSlide = (container) => {
    const slide = container.querySelector('.slide.translate-x-0');
    const description = slide.querySelector('.recommends-description');
    const image = slide.querySelector('.recommends-image');

    container.style.height = (description.offsetHeight + image.offsetHeight - 25) * 1.0 + 'px'
  }

  window.addEventListener('resize', 
    () => {
      const slideContainers = document.querySelectorAll('.slide-container');
      slideContainers.forEach(container => resizeSlide(container));
    }
  );

  document.addEventListener('turbolinks:load',
    () => {
      const slideContainers = document.querySelectorAll('.slide-container');
      slideContainers.forEach(container => resizeSlide(container));
    }
  )

  const slideContainers = document.querySelectorAll('.slide-container');
  slideContainers.forEach(container => resizeSlide(container));
});
