$ ->
  $('form.edit_article')
    .on 'ajax:success', (event, data, status, xhr) ->
      location.href = data.url
    .on 'ajax:error', (event, xhr, status, error) ->
      errorsList = $('.edit-article-errors ul')
      errorsList.find('li').remove()
      return unless xhr.responseJSON
      $.each xhr.responseJSON.errors, (index, error) ->
        errorsList.append("<li class='error'>#{error}</li>")
  $('.article-tags input[type=checkbox]')
    .on 'change', (ev) ->
      console.log("=====>")
      label = $(ev.target).parents('label:first')
      if $(ev.target).is( ":checked" )
        label.addClass('on')
      else
        label.removeClass('on')
  $('.article-tags input[type=checkbox]').each (index, el) ->
    label = $(el).parents('label:first')
    if $(el).is( ":checked" )
      label.addClass('on')
    else
      label.removeClass('on')
  $('.select2').select2
    width: '300px'